import React from 'react'
import { Link, navigate } from 'gatsby'
// import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
// import CardContent from '@material-ui/core/CardContent'
// import CardMedia from '@material-ui/core/CardMedia'
// import Typography from '@material-ui/core/Typography'
import { withStyles, makeStyles, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import currencyFormatter from 'currency-formatter'

const Product = props => {

  const useStyles = makeStyles({
    card: {
      maxWidth: '100%',
    },
    button: {
      color: `${process.envBUTTON_BACKGROUND_COLOR}`
    },
    price: {
      backgroundColor: `${process.envBUTTON_BACKGROUND_COLOR}`,
      textTransform: 'uppercase',
      padding: '13px',
      borderRadius: '10px',
      margin: '0px 0px 0px 0px',
      color: `${process.envBUTTON_COLOR}`,
      textDecoration: 'none',
      textAlign: 'center',
      fontSize: '1.3rem',
      display: 'block',
      border: 'none',
      '&:link': {
        textDecoration: 'none',
        color: `${process.envBUTTON_COLOR}`
      },
      '&:visited': {
        textDecoration: 'none',
        color: `${process.envBUTTON_COLOR}`
      },
      '&:hover': {
        textDecoration: 'none',
        color: `${process.envBUTTON_COLOR}`
      },
      '&:active': {
        textDecoration: 'none',
        color: `${process.envBUTTON_COLOR}`
      },
    }
  })

  const classes = useStyles()

  let price = (
    <div className={classes.price}>
      {process.env.SHOW_EMPTY}
    </div>
  )
  if (props.price > 0) {
    price = (
      <div className={classes.price}>
        {currencyFormatter.format(props.price, { locale: 'pt-BR' })}
      </div>
    )
  }

  const redirect = slug => {
    navigate(`/produtos/${slug}`)
  }

  const CssTypography = withStyles({
    root: {
      fontFamily: `${process.env.FONT_FAMILY}`
    },
  })(Typography)

  const CssCardContent = withStyles({
    root: {
      fontFamily: `${process.env.FONT_FAMILY}`,
      fontSize: `${process.env.FONT_SIZE}`,
    },
  })(CardContent)

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <Link to={`/produtos/${props.slug}`}>
          <CardMedia
            component="img"
            alt={props.title}
            image={props.imageThumbnail}
            title={props.title}
          />
        </Link>
        <CssCardContent onClick={() => redirect(props.slug)}>
          <CssTypography gutterBottom variant="h5" component="h2">
            {props.title}
          </CssTypography>
          {process.env.SHOW_PRICE === 'true' ? price : null}
        </CssCardContent>
      </CardActionArea>
    </Card>
  )
}

export default Product