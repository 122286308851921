import React from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles, Container } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Master from '../components/Master'
import Products from '../components/ListView/Products/Products'

const Index = ({ data }) => {
  const page = data.page
  const products = data.allProduct.nodes

  const useStyles = makeStyles(theme => ({
    content: {
      padding: theme.spacing(0, 3, 6),
    },
    banner: {
      '& img': {
        width: '100%'
      }
    },
    center: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
    },
    image: {
      width: '200px'
    },
    imageOnMediaQuery: {
      width: '200px'
    },
    button: {
      marginTop: '30px',
      padding: '10px',
      backgroundColor: `${process.env.COLOR1}`,
      color: `${process.env.COLOR2}`,
      textDecoration: 'none',
      fontSize: '25px',
      borderRadius: '5px'
    },
    imagePaper: {
      width: '100%',
      marginTop: '10px',
      borderRadius: '4px'
    },
    paper: {
      padding: '10px'
    },
    paperLi: {
      padding: '10px',
      borderTop: 'solid 1px #EEE',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      '&:first-child': {
        borderTop: 'none',
      },
      '& a': {
        textDecoration: 'none',
        color: '#555'
      },
      '& a:hover': {
        textDecoration: 'none',
        color: '#444'
      },
    }
  }));

  const classes = useStyles();

  const imageMediaQuery = useMediaQuery('(min-width:100px)');

  let image = <img className={classes.image} src="/case-comunicacao-visual_192x192.png" title={page.title} alt={page.title} />
  if (!imageMediaQuery) {
    image = <img className={classes.imageOnMediaQuery} src="/case-comunicacao-visual_192x192.png" title={page.title} alt={page.title} />
  }

  return (
    <Master title={page.title}>
      <GatsbySeo
        title={page.title}
        description={page.description}
        canonical={`${process.env.SITE_URL}`}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: `${process.env.SITE_URL}`,
          title: page.title,
          description: page.description,
          images: [
            {
              url: `${process.env.SITE_IMAGE_OG}`,
              width: 512,
              height: 512,
              alt: page.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <div className={classes.banner}>
        
      </div>
      <Container maxWidth="md" component="main" className={classes.content}>
        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 className={classes.center}>Seja bem vindo(a)!</h2>
            <div dangerouslySetInnerHTML={{ __html: page.details }} />
          </Grid>
        </Grid> */}
        <h2 className={classes.center}>Produtos em destaque</h2>
        <Products products={products} />
        <div className={classes.center}>
          <Link className={classes.button} to="/produtos">veja todos os produtos</Link>
        </div>
        <h1 className={classes.center}>
          {image}
        </h1>
      </Container>
    </Master>
  )
}

export default Index

export const query = graphql`
    query ($pageId: String!) {
      page(id: {eq: $pageId}) {
        title
        description
        keywords
        details
      }
      allProduct(sort: {fields: updatedAt, order: DESC}, filter: {isFeatured: {eq: true}}, limit: 6) {
        nodes {
          id
          title
          slug
          description
          price
          image            
          imageThumbnail
        }
      }
    }
 `